function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { RecommendationComponent } from '../common/recommendation-component';
export default class RecommendationList extends RecommendationComponent {
  init() {
    super.init();
    const ulElement = this.section.querySelector('ul.js-carousel-item-list');
    if (!ulElement || ulElement.innerHTML.trim() === '') {
      this.section.remove();
      return;
    }
    this.initCarousel();
  }
}
_defineProperty(RecommendationList, "selector", '.recommendation--list');
lm.DOMReady(() => RecommendationList.setupOnceDomIsReady());